/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.crossCircle::before {
  background: #4488bf;
  border-radius: 50%;
  color: white;
}

.cu-45 .PhoneInputCountry {
  pointer-events: none;
  cursor: not-allowed;
}
.error > div {
  border-color: red!important;
}